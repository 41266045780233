import React from "react";
import { useUserContext } from "contexts/UserContext";
import { useAnnouncementContext } from "contexts/AnnouncementContext";
import "./userstatusbanner.scss";
import { Trans } from "react-i18next";

// function FreeTierBanner() {
//   return null;
// return (
//   <div className="user-status-banner">
//     <Trans
//       i18nKey="message.currently_on_free_version"
//       components={[
//         <span key="currently_on_free_version"></span>,
//         <button
//           key="currently_on_free_version_button"
//           onClick={onUpgradeClick}
//         ></button>,
//       ]}
//     />
//   </div>
// );
// }

const SubscriptionExpiredBanner = () => {
  const { handleRenewSubscription } = useUserContext();

  return (
    <div className="user-status-banner user-status-banner--subscription">
      <Trans
        i18nKey="message.active_subscription_grace_period"
        components={[
          <button
            key="active_subscription_grace_period_button"
            onClick={() => handleRenewSubscription()}
          ></button>,
        ]}
      />
    </div>
  );
};

function UserStatusBanner() {
  const { isActiveSubscriptionExpired, isLoggedIn } = useUserContext();
  const { isActive } = useAnnouncementContext();

  if (isActive) return null;

  switch (true) {
    // case user?.revokedSub === true:
    //     return <SubRevokedBanner />;
    // case user?.subscription?.status === 'EXPIRED' || user?.unpaidInvoices === true:
    //     return <SubExpiredBanner />;
    // case user?.tier === "FREE":
    //   return <FreeTierBanner />;

    case isLoggedIn && isActiveSubscriptionExpired:
      return <SubscriptionExpiredBanner />;
    default:
      return null;
  }
}

export default React.memo(UserStatusBanner);
