import gql from "graphql-tag";
import {
  PAYMENT_SOURCE_DATA,
  BILLING_SUBSCRIPTION_DATA,
  INVOICE_PAYMENT_DATA,
} from "../fragments/payments";
import { PaymentSource, PurchasePlanInvoice } from "../schema/payments";

export const TOKENIZE_CHECKOUT_TOKEN = gql`
  mutation TokenizeCheckoutToken($token: String!) {
    source: tokenizeCheckoutToken(token: $token) {
      ...PaymentSourceData
    }
  }
  ${PAYMENT_SOURCE_DATA}
`;

export type TokenizeCheckoutTokenVars = { token: string };
export type TokenizeCheckoutTokenData = { source: PaymentSource };

export const SUBSCRIBE_TO_SYNDICATION = gql`
  mutation SubscribeToSyndication($id: ID!, $paymentProvider: PaymentProvider) {
    subscribeToSyndication(id: $id, paymentProvider: $paymentProvider) {
      id
      invoice {
        id
        payments {
          id
          paymentProvider
          status
          statusReason
          amount
          externalId
          metadata {
            ... on NashLinkPaymentMetadata {
              paymentUrl
            }

            ... on CheckoutPaymentMetadata {
              redirectLink3DS2
            }
          }
        }
      }
    }
  }
`;

export const UNSUBSCRIBE_FROM_SYNDICATION = gql`
  mutation UnsubscribeFromSyndication($id: ID!) {
    unsubscribeFromSyndication(id: $id) {
      ...BillingSubscriptionData
    }
  }
  ${BILLING_SUBSCRIPTION_DATA}
`;

export const ABANDON_SUBSCRIPTION_CANCELLATION = gql`
  mutation AbandonSubscriptionCancellation($subscriptionId: ID!) {
    abandonSubscriptionCancellation(subscriptionId: $subscriptionId)
  }
`;

export const PAY_REMAINING_INVOICE_BALANCE = gql`
  mutation PayRemainingInvoiceBalance(
    $invoiceId: ID!
    $paymentProvider: PaymentProvider!
  ) {
    payRemainingInvoiceBalance(
      invoiceId: $invoiceId
      paymentProvider: $paymentProvider
    ) {
      payment {
        ...InvoicePayment
      }
    }
  }
  ${INVOICE_PAYMENT_DATA}
`;

export const CHECK_CHECKOUT_SESSION_ID = gql`
  mutation CheckCheckoutSessionId($sessionId: ID!) {
    checkCheckoutSessionId(sessionId: $sessionId)
  }
`;

// *** - New payment flow requests
export type PurchasePlanInvoiceVars = { planId: string };
export type PurchasePlanInvoiceData = {
  purchasePlanInvoice: PurchasePlanInvoice;
};

export const PURCHASE_PLAN = gql`
  mutation PurchasePlanInvoice($planId: Int) {
    purchasePlanInvoice(planId: $planId) {
      billedFrom
      email
      fullName
      invoiceDate
      invoiceId
      paymentLink
      purchaseAmount
      purchaseCurrency
      status
      subscriptionEnd
      subscriptionStart
      purchaseType
      previousSubscriptionEnd
      upgradeDiscount
    }
  }
`;

export type CancelPlanSubscriptionVars = {};
export type CancelPlanSubscriptionData = {
  cancelSubscription: boolean;
};

export const CANCEL_PLAN_SUBSCRIPTION = gql`
  mutation Mutation {
    cancelSubscription
  }
`;
